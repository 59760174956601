import { Paper, styled } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useI18n } from '@braincube/i18n';
import { BraincubeDataGrid, useDataGridDefaultActions } from '@braincube/data-grid';
import { advancedTextSearch } from '@braincube/ui-lab';

import VersionApplicationSelector from 'components/Routes/AppsManager/Versions/ApplicationSelector';
import VersionDeletion from 'components/Routes/AppsManager/Versions/Deletion';
import { getAppVersions } from 'wsClient/AppsManagerWsClient';

const StyledDiv = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    height: `calc(100vh - ${theme.header.height}px)`,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
}));

export const GET_APP_VERSIONS_QUERY_KEY = 'get-app-versions';

/**
 * Offers management
 */
function VersionsComponent() {
    const i18n = useI18n();
    const apiRef = useGridApiRef();
    const [term, setTerm] = useState(null);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [deletion, setDeletion] = useState(null);
    const getDefaultActions = useDataGridDefaultActions(apiRef);

    const { data: versions, isPending: appVersionsIsLoading } = useQuery({
        queryKey: [GET_APP_VERSIONS_QUERY_KEY, selectedApplication?.uuid],
        queryFn: () => getAppVersions(selectedApplication?.uuid),
        enabled: selectedApplication !== null,
        initialData: [],
    });

    const getActions = useCallback(
        ({ id }) =>
            getDefaultActions({
                rowId: id,
                edit: {
                    hidden: true,
                },
                delete: {
                    hidden: versions.find((version) => version.id === id).latest,
                    callback: (versionUuid) => setDeletion(versions.find((version) => version.id === versionUuid)),
                },
            }),
        [getDefaultActions, versions]
    );

    const closeDeletion = useCallback(() => {
        setDeletion(null);
    }, []);

    const columns = useMemo(
        () => [
            {
                field: 'versionNumber',
                headerName: i18n.tc('ssoAdmin.versions.headerName.version'),
                flex: 1,
            },
            {
                field: 'nbDownload',
                headerName: i18n.tc('ssoAdmin.versions.headerName.nbDownload'),
                flex: 1,
            },
            {
                field: 'latest',
                headerName: i18n.tc('ssoAdmin.versions.headerName.latest'),
                flex: 1,
            },
            {
                field: 'binaryUrl',
                headerName: i18n.tc('ssoAdmin.versions.headerName.binaryUrl'),
            },
            {
                field: 'changelog',
                headerName: i18n.tc('ssoAdmin.versions.headerName.changelog'),
            },
            {
                field: 'filename',
                headerName: i18n.tc('ssoAdmin.versions.headerName.filename'),
            },
            {
                field: 'pkgName',
                headerName: i18n.tc('ssoAdmin.versions.headerName.package'),
            },
            {
                field: 'sha256',
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                getActions,
            },
        ],
        [getActions, i18n]
    );

    const filteredRows = useMemo(() => {
        return advancedTextSearch(term, versions);
    }, [term, versions]);

    const otherFilters = useCallback(
        () => (
            <VersionApplicationSelector
                selectedApplication={selectedApplication}
                setSelectedApplication={setSelectedApplication}
            />
        ),
        [selectedApplication]
    );

    const dataGridComponentProps = useMemo(
        () => ({
            toolbar: {
                onSearch: setTerm,
                toolbarActions: otherFilters,
            },
        }),
        [otherFilters]
    );

    const visibilityModel = Object.freeze({
        binaryUrl: false,
        changelog: false,
        filename: false,
        pkgName: false,
        sha256: false,
    });

    return (
        <>
            <StyledDiv>
                <StyledPaper>
                    <BraincubeDataGrid
                        checkboxSelection={false}
                        loading={appVersionsIsLoading}
                        apiRef={apiRef}
                        columns={columns}
                        rows={filteredRows}
                        componentsProps={dataGridComponentProps}
                        columnVisibilityModel={visibilityModel}
                    />
                </StyledPaper>
            </StyledDiv>
            {deletion && (
                <VersionDeletion onClose={closeDeletion} version={deletion} selectedApplication={selectedApplication} />
            )}
        </>
    );
}

export default VersionsComponent;
