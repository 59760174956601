import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CircularProgress, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ContentArea, DrawerActions, DrawerContent } from '@braincube/ui-lab';
import { fetchWithBearer } from '@braincube/jwt-browser';
import { useI18n } from '@braincube/i18n';

import SsoAceEditorWithSuspense from 'components/SsoAceEditor/WithSuspense';
import CancelButton from 'components/Buttons/Cancel';
import EditButton from 'components/Buttons/Edit';

import Diff from './diff';

export const PREF_TYPES = {
    SITES: 'sites',
    PRODUCTS: 'products',
    USERS: 'users',
};

function getPath(prefType, objectUuid) {
    let path = `https://${window.BC_API_ENDPOINTS_CONF.preference}/${prefType}/${objectUuid}/`;

    if (prefType === PREF_TYPES.USERS) {
        path = `https://${window.BC_API_ENDPOINTS_CONF.preference}/${prefType}/?uuid=${objectUuid}`;
    }

    return path;
}

const placeholderData = { settings: {} };

function getPrefs(objectUuid, prefType) {
    return fetchWithBearer(getPath(prefType, objectUuid)).then((response) => {
        if (response.ok) {
            if (response.status === 204) {
                return Promise.resolve(placeholderData);
            }

            return response.json();
        }

        return Promise.reject(response);
    });
}

function updatePrefs(objectUuid, prefType, prefs) {
    return fetchWithBearer(getPath(prefType, objectUuid), {
        method: 'PUT',
        body: JSON.stringify(JSON.parse(prefs)),
    });
}

function Prefs({ objectUuid, prefType, onCancel }) {
    const { data, isFetching } = useQuery({
        queryKey: ['get-prefs', prefType, objectUuid],
        queryFn: () => getPrefs(objectUuid, prefType),
        initialData: placeholderData,
        select: (json) => JSON.stringify(json.settings, null, 2),
    });

    const [confirm, setConfirm] = useState(false);

    const [tmp, setTmp] = useState(data);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const { mutate, isSuccess, isError } = useMutation({ mutationFn: () => updatePrefs(objectUuid, prefType, tmp) });

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(i18n.tc('preferences.updateOk'), { variant: 'success' });
        }
    }, [isSuccess, enqueueSnackbar, i18n]);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(i18n.tc('preferences.updateNok', { variant: 'error' }));
        }
    }, [isError, enqueueSnackbar, i18n]);

    useEffect(() => {
        setTmp(data);
    }, [data]);

    const isJSONValid = useMemo(() => {
        try {
            JSON.parse(tmp);
            return true;
        } catch (e) {
            return false;
        }
    }, [tmp]);

    const toggleConfirm = useCallback(() => {
        setConfirm(!confirm);
    }, [confirm]);

    const onSave = useCallback(() => {
        mutate();
        setConfirm(false);
    }, [mutate]);

    return (
        <>
            <DrawerContent>
                <ContentArea>
                    {isFetching ? <CircularProgress /> : <SsoAceEditorWithSuspense data={tmp} onChange={setTmp} />}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end">
                    <CancelButton onClick={onCancel} />
                    <EditButton onClick={toggleConfirm} disabled={!isJSONValid} />
                </Grid>
            </DrawerActions>
            {confirm && <Diff old={data} recent={tmp} onClose={toggleConfirm} onSave={onSave} />}
        </>
    );
}

export default Prefs;
