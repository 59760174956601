import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, Button, IconButton, styled, Grid } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { AppContext, setIsFetching } from '../../../../app-context';
import EditButton from '../../../Buttons/Edit';
import Edition from '../../../EntityManager/Edition';
import CancelButton from '../../../Buttons/Cancel';
import { encodeLicence, updateLicence } from '../../../../wsClient/LicenceWsClient';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import { getOffer } from '../../../../wsClient/AppsManagerWsClient';

const StyledCopyFieldContainer = styled(`div`)({
    display: 'flex',
    '& > div': {
        flex: 1,
    },
});

const StyledEncodeButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const ssoWs = new SsoWsClient();

function copyField(field) {
    field.current.disabled = false;
    field.current.select();
    field.current.disabled = true;
    document.execCommand('copy');
}

/**
 * Edit licence management
 */
function LicenceEdition({ licence, isUsed, expirationDate, onUpdate, onCancel }) {
    const { dispatch } = useContext(AppContext);
    const [comment, setComment] = useState(licence.comment || '');
    const [orderNumber, setOrderNumber] = useState(licence.orderNumber || '');
    const [site, setSite] = useState(undefined);
    const { state } = useContext(AppContext);
    const {
        data: { user },
    } = useMe();
    const i18n = useI18n();

    const [licenceEncode, setLicenceEncode] = useState('');
    const [offerName, setOfferName] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const myEncodeInput = useRef(null);
    const myIdInput = useRef(null);
    const myProductInput = useRef(null);
    const myModifiedByInput = useRef(null);

    useEffect(() => {
        setLicenceEncode('');
        setComment(licence.comment || '');
        setOrderNumber(licence.orderNumber === null ? '' : licence.orderNumber);
    }, [licence]);

    useEffect(() => {
        if (licence.siteUuid) {
            ssoWs.getSiteByUuid(licence.siteUuid, {
                onSuccess: ({ response }) => {
                    setSite(response);
                },
                onError: () => {
                    setSite('');
                },
            });
        } else {
            setSite('');
        }
    }, [licence.siteUuid]);

    const handleEdit = useCallback(() => {
        // test if orderNumber contains something other than a space or line break
        if (orderNumber && /\S/.test(orderNumber)) {
            dispatch(setIsFetching(true));
            updateLicence(licence.id, {
                comment,
                duration: licence.duration,
                orderNumber,
            })
                .then((response) => {
                    if (response.ok) {
                        return Promise.resolve();
                    }

                    return Promise.reject(response);
                })
                .then(() => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.licence.edition.success'), { variant: 'success' });
                    onUpdate();
                    onCancel();
                })
                .catch(() => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.licence.edition.error.default'), { variant: 'error' });
                    onCancel();
                });
        } else {
            enqueueSnackbar(i18n.tc('ssoAdmin.licence.addition.verifiers.orderNumber.empty'), { variant: 'error' });
        }
    }, [comment, dispatch, enqueueSnackbar, i18n, licence.duration, licence.id, onCancel, onUpdate, orderNumber]);

    const handleEncodage = useCallback(() => {
        encodeLicence(licence.id).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.licence.edition.encoded.success'), { variant: 'success' });
                response.json().then(setLicenceEncode);
                onUpdate();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.licence.edition.encoded.success'), { variant: 'error' });
            }
        });
    }, [enqueueSnackbar, i18n, licence.id, onUpdate]);

    const handleCommentChange = useCallback((e) => setComment(e.target.value), []);

    const handleOrderNumberChange = useCallback((e) => setOrderNumber(e.target.value), []);

    const handleCopy = useCallback(() => {
        copyField(myIdInput);
    }, []);

    const handleEncodeCopy = useCallback(() => {
        copyField(myEncodeInput);
    }, []);

    const handleModifiedByCopy = useCallback(() => {
        copyField(myModifiedByInput);
    }, []);

    const handleProductCopy = useCallback(() => {
        copyField(myProductInput);
    }, []);

    const { duration, maxChildren, createdAt, createdBy, id, modifiedBy, product, useDate, updatedAt } = licence;

    useEffect(() => {
        if (licence.offerUuid) {
            getOffer(licence.offerUuid).then((response) => {
                if (response.ok) {
                    response.json().then(({ name }) => {
                        setOfferName(name);
                    });
                }
            });
        }
    }, [licence?.offerUuid]);

    return (
        <Edition label={i18n.tc('ssoAdmin.licence.edition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.duration')}
                            value={duration === null ? '' : duration}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.comment')}
                            value={comment === null ? '' : comment}
                            onChange={handleCommentChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label="Site name"
                            value={site ? site.siteName.concat(' - ', site.groupName) : ''}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.offer')}
                            value={offerName}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.creationDate')}
                            value={createdAt === null ? '' : createdAt}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.createdBy')}
                            value={createdBy === null ? '' : createdBy}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <StyledCopyFieldContainer>
                            <TextField
                                disabled
                                label="Id"
                                value={id === null ? '' : id}
                                fullWidth
                                inputRef={myIdInput}
                            />

                            <IconButton onClick={handleCopy} size="large">
                                <FileCopyIcon />
                            </IconButton>
                        </StyledCopyFieldContainer>
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <StyledCopyFieldContainer>
                            <TextField
                                disabled
                                label={i18n.tc('ssoAdmin.licence.edition.fields.modifiedBy')}
                                value={modifiedBy === null ? '' : modifiedBy}
                                fullWidth
                                inputRef={myModifiedByInput}
                            />

                            <IconButton onClick={handleModifiedByCopy} size="large">
                                <FileCopyIcon />
                            </IconButton>
                        </StyledCopyFieldContainer>
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            label={i18n.tc('ssoAdmin.licence.addition.formFields.orderNumber')}
                            value={orderNumber === null ? '' : orderNumber}
                            onChange={handleOrderNumberChange}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <StyledCopyFieldContainer>
                            <TextField
                                disabled
                                label={i18n.tc('ssoAdmin.licence.edition.fields.product')}
                                value={product === null ? '' : product}
                                fullWidth
                                inputRef={myProductInput}
                            />

                            <IconButton onClick={handleProductCopy} size="large">
                                <FileCopyIcon />
                            </IconButton>
                        </StyledCopyFieldContainer>
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.updateDate')}
                            value={updatedAt === null ? '' : updatedAt}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.dateOfUse')}
                            value={useDate === null ? '' : useDate}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.state')}
                            value={isUsed === null ? '' : isUsed}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.expirationDate')}
                            value={expirationDate === null ? '' : expirationDate}
                            fullWidth
                        />
                    </StyledSpacedWrapper>

                    <StyledSpacedWrapper>
                        <TextField
                            disabled
                            label={i18n.tc('ssoAdmin.licence.edition.fields.maxChildren')}
                            value={maxChildren || 1}
                            fullWidth
                        />
                    </StyledSpacedWrapper>
                </ContentArea>

                <ContentArea>
                    <StyledEncodeButton
                        fullWidth
                        onClick={handleEncodage}
                        color="primary"
                        disabled={!user.rootAdmin && !state.meIsLicenceAdmin}
                        variant="contained"
                    >
                        {i18n.tc('ssoAdmin.licence.edition.actions.encode')}
                    </StyledEncodeButton>
                    {licenceEncode !== '' && (
                        <StyledCopyFieldContainer>
                            <TextField
                                defaultValue=""
                                value={licenceEncode.licence}
                                disabled
                                inputRef={myEncodeInput}
                            />
                            <IconButton onClick={handleEncodeCopy} size="large">
                                <FileCopyIcon />
                            </IconButton>
                        </StyledCopyFieldContainer>
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <EditButton disabled={!user.rootAdmin && !state.meIsLicenceAdmin} onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </Edition>
    );
}

LicenceEdition.propTypes = {
    licence: PropTypes.shape({
        comment: PropTypes.string.isRequired,
        count: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        createdBy: PropTypes.string.isRequired,
        distributor: PropTypes.object,
        duration: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        maxChildren: PropTypes.number.isRequired,
        modifiedBy: PropTypes.string.isRequired,
        orderNumber: PropTypes.string,
        product: PropTypes.string,
        random: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        useDate: PropTypes.string,
        useState: PropTypes.bool.isRequired,
        useUser: PropTypes.string,
        version: PropTypes.string.isRequired,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isUsed: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
};

export default LicenceEdition;
