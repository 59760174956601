import React, { useCallback, useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useMe } from '@braincube/header';
import tracker from '@braincube/tracker';

import { AppContext } from '../../../app-context';
import Delegates from './Delegates';
import Products from './Products';
import Providers from './Providers';
import Sites from './Sites';
import UserGroups from './UserGroups';
import Users from './Users';
import Distributors from './Distributors';
import Licences from './Licences';
import Preferences from './Preferences';

function RedirectToProviders() {
    return <Redirect to="/providers" />;
}

export function redirectToSso() {
    window.location.assign(`https://${window.BC_API_ENDPOINTS_CONF.sso}`);
}

function SsoRoutes() {
    const { state } = useContext(AppContext);
    const {
        data: { user },
    } = useMe();
    const location = useLocation();
    tracker.matomo.usePageView(location);

    const renderProviders = useCallback(() => {
        if (user.providerAdmin !== null || user.rootAdmin) {
            return <Providers />;
        }

        return redirectToSso();
    }, [user.providerAdmin, user.rootAdmin]);

    const renderSites = useCallback(() => {
        if (user.providerAdmin !== null || user.rootAdmin) {
            return <Sites />;
        }

        return redirectToSso();
    }, [user.providerAdmin, user.rootAdmin]);

    const renderProducts = useCallback(() => {
        if (user.providerAdmin !== null || user.rootAdmin) {
            return <Products />;
        }

        return redirectToSso();
    }, [user.providerAdmin, user.rootAdmin]);

    const renderUserGroups = useCallback(() => {
        if (user.rootAdmin) {
            return <UserGroups />;
        }

        return redirectToSso();
    }, [user.rootAdmin]);

    const renderDistributors = useCallback(() => {
        if (user.rootAdmin || state.meIsLicenceAdmin || state.meIsLicenceUsers) {
            return <Distributors />;
        }

        return redirectToSso();
    }, [state.meIsLicenceAdmin, state.meIsLicenceUsers, user.rootAdmin]);

    const renderLicences = useCallback(() => {
        if (user.rootAdmin || state.meIsLicenceAdmin || state.meIsLicenceUsers) {
            return <Licences />;
        }

        return redirectToSso();
    }, [state.meIsLicenceAdmin, state.meIsLicenceUsers, user.rootAdmin]);

    const renderDelegates = useCallback(() => {
        if (user.rootAdmin) {
            return <Delegates />;
        }

        return redirectToSso();
    }, [user.rootAdmin]);

    const renderPreferences = useCallback(() => {
        if (user.rootAdmin) {
            return <Preferences />;
        }

        return redirectToSso();
    }, [user.rootAdmin]);

    return (
        <>
            <Route path="/providers" render={renderProviders} exact />

            <Route path="/sites" render={renderSites} exact />

            <Route path="/products" render={renderProducts} exact />

            <Route path="/users" component={Users} exact />

            <Route path="/user-groups" render={renderUserGroups} exact />

            <Route path="/distributors" render={renderDistributors} exact />

            <Route path="/licences" render={renderLicences} exact />

            <Route path="/delegates" render={renderDelegates} exact />

            <Route path="/preferences" render={renderPreferences} exact />

            <Route path="/" render={RedirectToProviders} exact />
        </>
    );
}

export default SsoRoutes;
