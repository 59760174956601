import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField, Autocomplete, ListItem, Grid } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { naturalSort } from '@braincube/ui-lab/lib/services/advancedTextSearch';
import { AutocompleteInputRender, SmallCheckBox, DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { getAllApps, updateOffer } from '../../../../wsClient/AppsManagerWsClient';
import EditButton from '../../../Buttons/Edit';
import Edition from '../../../EntityManager/Edition';
import CancelButton from '../../../Buttons/Cancel';
import ListboxComponent from '../../../ListboxComponent';
import { StyledSpacedWrapper } from '../../../StyledComponents';

function renderOption(props, option, { selected }) {
    return (
        <ListItem button {...props}>
            <SmallCheckBox option={option} selected={selected} />
        </ListItem>
    );
}

function getOptionLabel(option) {
    return option.label;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

/**
 * Edit offer management
 */
function OfferEdition({ offer, onCancel, onUpdate }) {
    const [name, setName] = useState(offer.name);
    const [apps, setApps] = useState([]);
    const [tempApps, setTempApps] = useState(offer.apps);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => setName(offer.name), [offer.name]);
    useEffect(() => setTempApps(offer.apps), [offer.apps]);

    useEffect(() => {
        getAllApps().then(setApps);
    }, []);

    const handleEdit = useCallback(() => {
        updateOffer(offer.id, {
            id: offer.id,
            name,
            apps: tempApps,
        }).then((response) => {
            if (response.ok) {
                enqueueSnackbar(i18n.tc('ssoAdmin.offers.notistack.update.success'), { variant: 'success' });
                onUpdate();
            } else {
                enqueueSnackbar(i18n.tc('ssoAdmin.offers.notistack.update.error'), { variant: 'error' });
                onCancel();
            }
        });
    }, [enqueueSnackbar, i18n, name, offer.id, onCancel, onUpdate, tempApps]);

    const selectedApps = useMemo(() => {
        return apps
            .filter((app) => tempApps.includes(app.id))
            .map((app) => ({
                label: app.name,
                value: app.id,
            }))
            .sort((offer1, offer2) => naturalSort(offer1, offer2, ['label']));
    }, [apps, tempApps]);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const renderInput = useCallback(
        (params) => (
            <AutocompleteInputRender
                TextFieldProps={params}
                label={i18n.tc('ssoAdmin.offers.fields.apps')}
                placeholder={i18n.tc('ssoAdmin.search')}
            />
        ),
        [i18n]
    );

    const handleAppChange = useCallback((event, newValue) => {
        setTempApps(newValue.map((selected) => selected.value));
    }, []);

    return (
        <Edition label={i18n.tc('ssoAdmin.offers.edition.title')} onCancel={onCancel}>
            <DrawerContent>
                <ContentArea>
                    <StyledSpacedWrapper>
                        <TextField
                            fullWidth
                            label={i18n.tc('ssoAdmin.offers.fields.name')}
                            value={name}
                            onChange={handleNameChange}
                            disabled
                        />
                    </StyledSpacedWrapper>

                    {apps.length > 0 && (
                        <Autocomplete
                            selectOnFocus
                            clearOnBlur
                            multiple
                            handleHomeEndKeys
                            renderInput={renderInput}
                            ListboxComponent={ListboxComponent}
                            renderOption={renderOption}
                            options={apps.map((app) => ({
                                label: app.name,
                                value: app.id,
                            }))}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={selectedApps}
                            onChange={handleAppChange}
                        />
                    )}
                </ContentArea>
            </DrawerContent>
            <DrawerActions>
                <Grid container justifyContent="flex-end" mt={1}>
                    <Grid item mr={1}>
                        <CancelButton onClick={onCancel} />
                    </Grid>
                    <EditButton onClick={handleEdit} />
                </Grid>
            </DrawerActions>
        </Edition>
    );
}

OfferEdition.propTypes = {
    offer: PropTypes.shape({
        apps: PropTypes.array.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default OfferEdition;
