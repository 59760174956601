import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { useMe } from '@braincube/header';

import CancelButton from '../../../Buttons/Cancel';
import EditButton from '../../../Buttons/Edit';
import SsoWsClient from '../../../../wsClient/SsoWsClient';

const StyledButton = styled(`div`)(({ theme }) => ({
    paddingTop: theme.spacing(2),
}));

const ssoWs = new SsoWsClient();

/**
 * This component displays a link to change the user's email address.
 */
function ChangeEmailLinks({ email, provider, onForceChangeSuccess }) {
    const {
        data: { user },
    } = useMe();
    const i18n = useI18n();

    const [forceChangePopinIsOpen, setForceChangePopinIsOpen] = useState(false);
    const [forceChangeEmailValue, setForceChangeEmailValue] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const onClick = useCallback(() => {
        const events = {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.sendChangeMailLinkOK'), { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.notifications.sendChangeMailLinkNOK'), { variant: 'error' });
            },
        };
        ssoWs.changeEmail(email, provider, events);
    }, [email, enqueueSnackbar, i18n, provider]);

    const onForceChangeValidate = useCallback(() => {
        ssoWs.forceChangeEmail(email, forceChangeEmailValue, provider, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.updateOK'), { variant: 'success' });
                setForceChangePopinIsOpen(false);
                onForceChangeSuccess();
            },
            onError: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.user.updateNOK'), { variant: 'error' });
            },
        });
    }, [email, enqueueSnackbar, forceChangeEmailValue, i18n, onForceChangeSuccess, provider]);

    const handleClosePopin = useCallback(() => setForceChangePopinIsOpen(false), []);

    const handleOpenPopin = useCallback(() => setForceChangePopinIsOpen(true), []);

    const handleEmailChange = useCallback((e) => setForceChangeEmailValue(e.target.value), []);

    const renderForceChangeEmailPopinIfNeeded = useCallback(() => {
        return (
            <Dialog open={forceChangePopinIsOpen} onClose={handleClosePopin} fullWidth>
                <DialogTitle>{i18n.tc('ssoAdmin.user.actions.forceChangeEmail')}</DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={handleEmailChange}
                        value={forceChangeEmailValue}
                        placeholder={email}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleClosePopin} />
                    <EditButton onClick={onForceChangeValidate} />
                </DialogActions>
            </Dialog>
        );
    }, [
        email,
        forceChangeEmailValue,
        forceChangePopinIsOpen,
        handleClosePopin,
        handleEmailChange,
        i18n,
        onForceChangeValidate,
    ]);

    return (
        <>
            <div>
                <Button variant="contained" onClick={onClick} fullWidth>
                    {i18n.tc('ssoAdmin.user.actions.sendChangeEmailLink')}
                </Button>
            </div>
            {onForceChangeSuccess !== null && (
                <>
                    <StyledButton>
                        <Button
                            variant="contained"
                            onClick={handleOpenPopin}
                            fullWidth
                            disabled={!user.rootAdmin && !user.providerAdmin}
                        >
                            {i18n.tc('ssoAdmin.user.actions.manualChangeEmail')}
                        </Button>
                    </StyledButton>
                    {renderForceChangeEmailPopinIfNeeded()}
                </>
            )}
        </>
    );
}

ChangeEmailLinks.propTypes = {
    /** User identifier. Used to initiate the call to the webservice to change the email */
    email: PropTypes.string.isRequired,
    /** Provider for which to change the user email */
    provider: PropTypes.string,
    onForceChangeSuccess: PropTypes.func,
};

ChangeEmailLinks.defaultProps = {
    provider: null,
    onForceChangeSuccess: null,
};

export default ChangeEmailLinks;
