import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';

import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import Deletion from '../../../EntityManager/Deletion';
import DeleteButton from '../../../Buttons/Delete';
import CancelButton from '../../../Buttons/Cancel';
import FormField from '../../../FormField';
import { StyledButtonContainer, StyledSpacedWrapper } from '../../../StyledComponents';

const ssoWs = new SsoWsClient();

/**
 * Edit provider management
 */
function ProviderDeletion({ provider, onDelete, onCancel }) {
    const {
        data: { user },
    } = useMe();
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const handleDelete = useCallback(() => {
        ssoWs.deleteProvider(provider.name, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.providers.notistack.delete.success'), { variant: 'success' });
                onDelete();
            },
            onError: (brainWsResponse, xhr) => enqueueSnackbar(xhr.response, { variant: 'error' }),
        });
    }, [enqueueSnackbar, i18n, onDelete, provider.name]);

    return (
        <Deletion label={i18n.tc('ssoAdmin.providers.deletion.title')} onCancel={onCancel}>
            <FormField>
                <StyledSpacedWrapper>
                    <TextField
                        label={i18n.tc('ssoAdmin.providers.fields.name')}
                        disabled
                        value={provider.name}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <StyledSpacedWrapper>
                    <TextField
                        disabled
                        label={i18n.tc('ssoAdmin.providers.fields.url')}
                        value={provider.url}
                        fullWidth
                    />
                </StyledSpacedWrapper>

                <TextField
                    disabled
                    label={i18n.tc('ssoAdmin.providers.fields.vendor')}
                    value={provider.vendor}
                    fullWidth
                />
            </FormField>
            <StyledButtonContainer>
                <CancelButton onClick={onCancel} />
                <DeleteButton onClick={handleDelete} disabled={!user.rootAdmin} />
            </StyledButtonContainer>
        </Deletion>
    );
}

ProviderDeletion.propTypes = {
    provider: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
        vendor: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ProviderDeletion;
