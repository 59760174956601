import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LockClockIcon from '@mui/icons-material/LockClock';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, styled, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { add } from 'date-fns';

import { useUserPreferences } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import FormField from 'components/FormField';

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
}));

const renderDateTimePickerInput = (props) => <TextField fullWidth {...props} />;

function BlockUserDialog({ isOpen, onCancel, onEndDateSelection }) {
    const {
        data: {
            general: { locale },
        },
    } = useUserPreferences();
    const i18n = useI18n();

    const [selectedDate, setSelectedDate] = useState(add(new Date(), { hours: 1 }));

    const handleDateChange = useCallback((date) => setSelectedDate(date), [setSelectedDate]);
    const handleValidate = useCallback(() => onEndDateSelection(selectedDate), [onEndDateSelection, selectedDate]);

    const cancelLabel = useMemo(() => <Button color="secondary">{i18n.tc('ssoAdmin.actions.cancel')}</Button>, [i18n]);
    const okLabel = useMemo(
        () => (
            <Button variant="contained" color="primary">
                {i18n.tc('ssoAdmin.actions.ok')}
            </Button>
        ),
        [i18n]
    );

    return (
        <Dialog open={isOpen} fullWidth onClose={onCancel}>
            <DialogTitle>
                <StyledTitle>
                    <LockClockIcon />
                    {i18n.tc('ssoAdmin.user.actions.blockUser.title')}
                    <StyledCloseButton aria-label="Close" onClick={onCancel} size="large">
                        <CloseIcon />
                    </StyledCloseButton>
                </StyledTitle>
            </DialogTitle>
            <FormField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        ampm={locale !== 'fr'}
                        fullWidth
                        label={i18n.tc('ssoAdmin.user.actions.blockUser.blockUserUntil')}
                        value={selectedDate}
                        onChange={handleDateChange}
                        cancelLabel={cancelLabel}
                        okLabel={okLabel}
                        disablePast
                        renderInput={renderDateTimePickerInput}
                        desktopModeMediaQuery="@media all"
                    />
                </LocalizationProvider>
            </FormField>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleValidate}>
                    {i18n.tc('ssoAdmin.actions.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

BlockUserDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onEndDateSelection: PropTypes.func.isRequired,
};

export default BlockUserDialog;
