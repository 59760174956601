import { NavLink } from 'react-router-dom';
import React, { useContext } from 'react';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import BusinessIcon from '@mui/icons-material/BusinessRounded';
import { Settings } from '@mui/icons-material';

import {
    HandshakeOutline,
    SmileyBraincube,
    AccountGroup,
    Certificate,
    Distributor,
    Authentification,
} from '@braincube/svg';
import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import { AppContext } from '../../../app-context';
import Exit from '../Exit';

const activeClassName = 'Mui-selected';

function SsoNavList() {
    const { state } = useContext(AppContext);
    const {
        data: { user },
    } = useMe();
    const i18n = useI18n();
    const url = window.location.href;

    return (
        <List disablePadding>
            {(user.providerAdmin !== null || user.rootAdmin) && (
                <>
                    <ListItemButton component={NavLink} to="/providers" activeClassName={activeClassName}>
                        {url.includes('providers') ? (
                            <ListItemIcon>
                                <HandshakeOutline htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <HandshakeOutline />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.providers')} />
                    </ListItemButton>

                    <ListItemButton component={NavLink} to="/sites" activeClassName={activeClassName}>
                        {url.includes('sites') ? (
                            <ListItemIcon>
                                <BusinessIcon htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <BusinessIcon />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.sites')} />
                    </ListItemButton>

                    <ListItemButton component={NavLink} to="/products" activeClassName={activeClassName}>
                        {url.includes('products') ? (
                            <ListItemIcon>
                                <SmileyBraincube htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <SmileyBraincube />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.products')} />
                    </ListItemButton>

                    <Divider />
                </>
            )}

            <ListItemButton component={NavLink} to="/users" activeClassName={activeClassName}>
                {url.includes('users') ? (
                    <ListItemIcon>
                        <FaceIcon htmlColor="#000" />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <FaceIcon />
                    </ListItemIcon>
                )}
                <ListItemText primary={i18n.tc('ssoAdmin.nav.users')} />
            </ListItemButton>

            {user.rootAdmin && (
                <>
                    <ListItemButton component={NavLink} to="/user-groups" activeClassName={activeClassName}>
                        {url.includes('user-groups') ? (
                            <ListItemIcon>
                                <AccountGroup htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <AccountGroup />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.groups')} />
                    </ListItemButton>

                    <ListItemButton component={NavLink} to="/delegates" activeClassName={activeClassName}>
                        {url.includes('delegates') ? (
                            <ListItemIcon>
                                <Authentification htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <Authentification />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.delegates')} />
                    </ListItemButton>
                </>
            )}
            <Divider />
            {(user.rootAdmin || state.meIsLicenceAdmin || state.meIsLicenceUsers) && (
                <>
                    <ListItemButton component={NavLink} to="/distributors" activeClassName={activeClassName}>
                        {url.includes('distributors') ? (
                            <ListItemIcon>
                                <Distributor htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <Distributor />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.distributors')} />
                    </ListItemButton>

                    <ListItemButton component={NavLink} to="/licences" activeClassName={activeClassName}>
                        {url.includes('licences') ? (
                            <ListItemIcon>
                                <Certificate htmlColor="#000" />
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <Certificate />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={i18n.tc('ssoAdmin.nav.licences')} />
                    </ListItemButton>
                </>
            )}
            <Divider />
            <ListItemButton component={NavLink} to="/preferences" activeClassName={activeClassName}>
                {url.includes('preferences') ? (
                    <ListItemIcon>
                        <Settings htmlColor="#000" />
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                )}
                <ListItemText primary={i18n.tc('ssoAdmin.nav.preferences')} />
            </ListItemButton>
            <Divider />
            <Exit />
            <Divider />
        </List>
    );
}

export default SsoNavList;
