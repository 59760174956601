import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import {
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
    Autocomplete,
    Chip,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import { StyledCheckbox } from 'components/GrantList/GrantAddition';

import { AppContext, setIsFetching } from '../../../../app-context';
import EntityManager from '../../../EntityManager';
import LicenceAddition from './LicenceAddition';
import LicenceEdition from './LicenceEdition';
import { getDistributors, getActivatedLicences } from '../../../../wsClient/LicenceWsClient';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import LicenceDeletion from './LicenceDeletion';
import CellTooltip from '../../../CellTooltip';
import { ActionsCell } from '../../../DataGridPro';
import { NoDistributor } from './NoDistributor';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledFormGroup = styled(FormGroup)({
    alignItems: 'center',
});

const StyledLicenceSelector = styled(FormControl)(({ theme }) => ({
    marginRight: theme.spacing(2),
    minWidth: 150,
}));

const StyledLicenceSelectorAutoComplete = styled(FormControl)({
    marginRight: 0,
    flex: 1,
});

const StyledDefault = styled(`em`)({
    color: grey[700],
});

export function timestampToFormattedDate(UNIXtimestamp) {
    const a = new Date(UNIXtimestamp);
    const year = a.getFullYear();
    const month = `0${a.getMonth() + 1}`.slice(-2);
    const date = `0${a.getDate()}`.slice(-2);
    const hour = `0${a.getHours() - 2}`.slice(-2);
    const min = `0${a.getMinutes()}`.slice(-2);
    const sec = `0${a.getSeconds()}`.slice(-2);
    return `${year}-${month}-${date}T${hour}:${min}:${sec}`;
}

const ssoWs = new SsoWsClient();

function renderInput(params) {
    return <TextField {...params} label="Licence state" />;
}

function getOptionLabel(option) {
    return option;
}

function renderOption(props, option, { selected }) {
    return (
        <div {...props}>
            <StyledCheckbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            {option}
        </div>
    );
}

function AddRenderer({ selectedDistributor, siteList, onFetch, onHideDrawer }) {
    const onAdd = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    return (
        <LicenceAddition
            distributor={selectedDistributor}
            siteList={siteList}
            onAdd={onAdd}
            onCancel={onHideDrawer}
            onError={onHideDrawer}
        />
    );
}

function EditRenderer({ licence, onFetch, onHideDrawer }) {
    return (
        <LicenceEdition
            licence={licence}
            isUsed={licence.isUsed}
            expirationDate={licence.expirationDate}
            onUpdate={onFetch}
            onCancel={onHideDrawer}
        />
    );
}

function DelRenderer({ licence, setDeletingLicence, onFetch, onHideDrawer }) {
    const onDelete = useCallback(() => {
        onFetch();
        onHideDrawer();
    }, [onFetch, onHideDrawer]);

    const onCancel = useCallback(() => {
        setDeletingLicence(null);
        onHideDrawer();
    }, [onHideDrawer, setDeletingLicence]);

    return <LicenceDeletion licence={licence} onDelete={onDelete} onCancel={onCancel} />;
}

function renderTags(values, getTagsProps) {
    return values.map((value, index) => {
        let color = 'default';

        if (value === 'Expired') {
            color = 'error';
        }

        if (value === 'Used') {
            color = 'success';
        }

        return <Chip {...getTagsProps({ index })} key={value} label={value} color={color} />;
    });
}

function UsageRenderer({ row }) {
    let color = 'default';

    if (row.isUsed === 'Expired') {
        color = 'error';
    }

    if (row.isUsed === 'Used') {
        color = 'success';
    }

    return <Chip color={color} label={row.isUsed} />;
}

/**
 * Licences management
 */
function LicencesComponent() {
    const { dispatch, state } = useContext(AppContext);
    const {
        data: { user: me },
    } = useMe();
    const [distributors, setDistributors] = useState([]);
    const [selectedDistributor, setSelectedDistributor] = useState(undefined);
    const [selectedState, setSelectedState] = useState(['Used', 'Expired', 'Unused']);
    const [licences, setLicences] = useState([]);
    const [deletingLicence, setDeletingLicence] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [users, setUsers] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const licenceStates = useMemo(() => ['Used', 'Unused', 'Expired'], []);
    const apiRef = useGridApiRef();
    const i18n = useI18n();

    const fetchLicences = useCallback(
        (distributorId = selectedDistributor.id) => {
            dispatch(setIsFetching(true));

            getActivatedLicences(distributorId).then((response) => {
                if (response.ok) {
                    response.json().then((distributorList) => {
                        // eslint-disable-next-line max-nested-callbacks
                        setLicences(
                            distributorList.licenceList.map((licence) => {
                                const createdAtDate = new Date(licence.createdAt.toString());
                                const usedAtDate = licence.useDate === null ? '' : new Date(licence.useDate.toString());
                                const createdAtTimeStamp = createdAtDate.setDate(createdAtDate.getDate());
                                const currentDate = timestampToFormattedDate(new Date().getTime());

                                if (licence.useDate) licence.useDate = timestampToFormattedDate(usedAtDate);

                                if (licence.updatedAt) licence.updatedAt = timestampToFormattedDate(licence.updatedAt);

                                licence.createdAt = timestampToFormattedDate(createdAtTimeStamp);

                                if (licence.duration <= 0) {
                                    licence.expirationDate =
                                        licence.useDate === null ? licence.createdAt : licence.useDate;
                                } else if (!licence.useState) {
                                    licence.expirationDate = '';
                                } else {
                                    licence.expirationDate = timestampToFormattedDate(
                                        usedAtDate.setDate(usedAtDate.getDate() + licence.duration)
                                    );
                                }

                                if (licence.expirationDate !== '' && licence.expirationDate < currentDate) {
                                    licence.isUsed = 'Expired';
                                } else {
                                    licence.isUsed = licence.useState ? 'Used' : 'Unused';
                                }
                                // eslint-disable-next-line max-nested-callbacks
                                const licenceUser = users.find((user) => user.uuid === licence.createdBy);
                                if (typeof licenceUser !== 'undefined') licence.createdBy = licenceUser.email;
                                return licence;
                            })
                        );
                    });
                }
                dispatch(setIsFetching(false));
            });
        },
        [dispatch, selectedDistributor, users]
    );

    const fetchUsers = useCallback(() => {
        dispatch(setIsFetching(true));

        ssoWs.getAllUsers({
            onSuccess: ({ response }) => {
                dispatch(setIsFetching(false));
                setUsers(response.users);
            },
            onError: (brainWsResponse, xhr) => {
                dispatch(setIsFetching(false));
                enqueueSnackbar(xhr.response, { variant: 'error' });
            },
        });
    }, [dispatch, enqueueSnackbar]);

    const fetchDistributors = () => {
        getDistributors().then((response) => {
            if (response.ok) {
                response.json().then((value) => {
                    setDistributors(value.distributorList);
                    setSelectedDistributor(value.distributorList[0] ?? null);
                });
            }
        });
    };

    const fetchSites = () => {
        ssoWs.getSites({
            onSuccess: ({ response }) => {
                setSiteList(response.siteList);
            },
            onError: () => {},
        });
    };

    const addRenderer = useCallback(
        (hideDrawers) => (
            <AddRenderer
                selectedDistributor={selectedDistributor}
                siteList={siteList}
                onFetch={fetchLicences}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchLicences, selectedDistributor, siteList]
    );

    const editRenderer = useCallback(
        (licence, hideDrawers) => <EditRenderer licence={licence} onFetch={fetchLicences} onHideDrawer={hideDrawers} />,
        [fetchLicences]
    );

    const delRenderer = useCallback(
        (licence, hideDrawers) => (
            <DelRenderer
                licence={licence}
                setDeletingLicence={setDeletingLicence}
                onFetch={fetchLicences}
                onHideDrawer={hideDrawers}
            />
        ),
        [fetchLicences]
    );

    const handleDelete = useCallback(
        (licenceId) => {
            const licenceToDelete = licences.find((licence) => licence.id === licenceId);

            if (licenceToDelete) {
                setDeletingLicence(licenceToDelete);
            }
        },
        [licences]
    );

    const columns = useMemo(
        () => [
            {
                field: 'duration',
                headerName: 'Duration',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'comment',
                headerName: 'Comment',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'createdAt',
                headerName: 'Creation date',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'expirationDate',
                headerName: 'Expiration date',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'createdBy',
                headerName: 'Created by',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'useDate',
                headerName: 'Date of Use',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ value }) => <CellTooltip value={value} />,
            },
            {
                field: 'isUsed',
                headerName: 'State',
                flex: 1,
                // eslint-disable-next-line react/prop-types
                renderCell: ({ row }) => <UsageRenderer row={row} />,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 120,
                renderCell: ({ id }) => (
                    <ActionsCell
                        id={id}
                        apiRef={apiRef}
                        onDelete={handleDelete}
                        preventDelete={!state.meIsLicenceAdmin && !me.rootAdmin}
                        preventEdit
                    />
                ),
            },
        ],
        [apiRef, handleDelete, me.rootAdmin, state.meIsLicenceAdmin]
    );

    const handleDistributorChange = useCallback((e) => {
        setSelectedDistributor(e.target.value);
    }, []);

    const handleSelectedStateChange = useCallback((event, value) => setSelectedState(value), []);

    const defaultLicencesValue = useMemo(() => [licenceStates[0], licenceStates[1], licenceStates[2]], [licenceStates]);

    const filter = useMemo(
        () => (
            <StyledFormGroup row>
                <StyledLicenceSelector>
                    <InputLabel>Distributor</InputLabel>
                    <Select label="Distributor" value={selectedDistributor} onChange={handleDistributorChange}>
                        <MenuItem disabled value="def">
                            <StyledDefault>Select a distributor</StyledDefault>
                        </MenuItem>
                        {distributors.map((distributor) => (
                            <MenuItem key={distributor.name} value={distributor}>
                                {distributor.name}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledLicenceSelector>
                <StyledLicenceSelectorAutoComplete>
                    <Autocomplete
                        renderInput={renderInput}
                        options={licenceStates}
                        onChange={handleSelectedStateChange} // prints the selected value
                        multiple
                        disableCloseOnSelect
                        getOptionLabel={getOptionLabel}
                        defaultValue={defaultLicencesValue}
                        renderOption={renderOption}
                        renderTags={renderTags}
                    />
                </StyledLicenceSelectorAutoComplete>
            </StyledFormGroup>
        ),
        [
            defaultLicencesValue,
            distributors,
            handleDistributorChange,
            handleSelectedStateChange,
            licenceStates,
            selectedDistributor,
        ]
    );

    useEffect(() => {
        fetchSites();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        if (users && users.length) fetchDistributors();
    }, [users]);

    useEffect(() => {
        if (selectedDistributor) fetchLicences(selectedDistributor.id);
    }, [fetchLicences, selectedDistributor]);

    if (distributors.length === 0 || selectedDistributor === null) {
        return <NoDistributor />;
    }

    return (
        <EntityManager
            apiRef={apiRef}
            columns={columns}
            onDeletion={deletingLicence}
            entities={licences.filter((licence) => selectedState.includes(licence.isUsed))}
            canAdd={state.meIsLicenceAdmin || me.rootAdmin}
            addRenderer={addRenderer}
            editRenderer={editRenderer}
            delRenderer={delRenderer}
            filter={filter}
            loadingDataPending={state.isFetching}
            creationLabel={i18n.tc('ssoAdmin.licence.addition.title')}
        />
    );
}

export default LicencesComponent;
