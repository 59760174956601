import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button, FormControl, InputLabel, MenuItem, Select, styled, Tabs, TextField, Grid } from '@mui/material';
import { differenceInMinutes } from 'date-fns';

import { useMe } from '@braincube/header';
import { useI18n } from '@braincube/i18n';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EditButton from '../../../Buttons/Edit';
import Edition from '../../../EntityManager/Edition';
import ChangeEmailLinks from './ChangeEmailLinks';
import GenerateCertificateLink from './GenerateCertificateLink';
import ResetPasswordLink from './ResetPasswordLink';
import RevokeCertificateLink from './RevokeCertificateLink';
import { SSO_BRANCH } from './UserAddition';
import UserGrantsList from './UserGrantsList';
import UserGroupsList from './UserGroupsList';
import CancelButton from '../../../Buttons/Cancel';
import { StyledSpacedWrapper } from '../../../StyledComponents';
import BlockUserDialog from './BlockUserDialog';
import Prefs, { PREF_TYPES } from '../../../Prefs';

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledButtonWrapper = styled(`div`)(({ theme }) => ({
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
}));

const ssoWs = new SsoWsClient();

/**
 * Edit user management
 */
function UserEdition({ user, onUpdate, onCancel, isBlocked }) {
    const {
        data: { user: me },
    } = useMe();
    const { email, uuid, certSerial, ssoBranch, gidNumber, homeDirectory, uidNumber, providers, owner } = user;

    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [providerAdmin, setProviderAdmin] = useState(user.providerAdmin || '');
    const [loginShell, setLoginShell] = useState(user.loginShell || '');
    const [tab, setTab] = useState(0);
    const [blockUserDialogIsOpen, setBlockUserDialogIsOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    const openBlockUserDialog = useCallback(() => setBlockUserDialogIsOpen(true), [setBlockUserDialogIsOpen]);
    const closeBlockUserDialog = useCallback(() => setBlockUserDialogIsOpen(false), [setBlockUserDialogIsOpen]);

    useEffect(() => {
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setProviderAdmin(user.providerAdmin || '');
        setLoginShell(user.loginShell || '');
    }, [user]);

    const handleEdit = useCallback(() => {
        ssoWs.updateUser(
            {
                ...user,
                firstName,
                lastName,
                providerAdmin,
                email: user.email,
                loginShell: loginShell || null,
            },
            user.email,
            {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.update.success'), { variant: 'success' });
                    onUpdate();
                },
                onError: (brainWsResponse, xhr) => {
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    onCancel();
                },
            }
        );
    }, [enqueueSnackbar, firstName, i18n, lastName, loginShell, onCancel, onUpdate, providerAdmin, user]);

    const onUserBlockingEndDateSelection = useCallback(
        (blockingEndDate) => {
            closeBlockUserDialog();
            ssoWs.blockUser(email, differenceInMinutes(blockingEndDate, new Date()), {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.block.success'), { variant: 'success' });
                    onUpdate();
                },
                onError: () => enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.block.error'), { variant: 'error' }),
            });
        },
        [closeBlockUserDialog, email, enqueueSnackbar, i18n, onUpdate]
    );

    const handleChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleFirstnameChange = useCallback((e) => setFirstName(e.target.value), []);

    const handleLastnameChange = useCallback((e) => setLastName(e.target.value), []);

    const handleProviderAdminChange = useCallback((e) => setProviderAdmin(e.target.value), []);

    const handleShellChange = useCallback((e) => setLoginShell(e.target.value), []);

    const handleUnblockUser = useCallback(() => {
        ssoWs.unblockUser(email, {
            onSuccess: () => {
                enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.unblock.success'), {
                    variant: 'success',
                });
                onUpdate();
            },
            onError: () =>
                enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.unblock.error'), {
                    variant: 'error',
                }),
        });
    }, [email, enqueueSnackbar, i18n, onUpdate]);

    const handleRevoke2FA = useCallback(
        () =>
            ssoWs.disableOtp(user.uuid, {
                onSuccess: () => {
                    enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.disable2FA.success'), {
                        variant: 'success',
                    });
                    onUpdate();
                },
                onError: () =>
                    enqueueSnackbar(i18n.tc('ssoAdmin.users.notistack.disable2FA.error'), {
                        variant: 'error',
                    }),
            }),
        [enqueueSnackbar, i18n, onUpdate, user.uuid]
    );

    return (
        <Edition label={i18n.tc('ssoAdmin.users.edition.title')} onCancel={onCancel}>
            <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                value={tab}
                onChange={handleChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.users.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.users.tabs.commands')} />
                <StyledTab label={i18n.tc('ssoAdmin.users.tabs.grants')} />
                <StyledTab label={i18n.tc('ssoAdmin.users.tabs.groups')} disabled={!me.rootAdmin} />
                <StyledTab label="Prefs" />
            </Tabs>
            {tab === 0 && (
                <>
                    <DrawerContent>
                        <ContentArea>
                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.firstname')}
                                    value={firstName}
                                    onChange={handleFirstnameChange}
                                    fullWidth
                                    disabled={!me.rootAdmin && !me.providerAdmin}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.lastname')}
                                    value={lastName}
                                    onChange={handleLastnameChange}
                                    fullWidth
                                    disabled={!me.rootAdmin && !me.providerAdmin}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.providerAdmin')}
                                    value={providerAdmin}
                                    onChange={handleProviderAdminChange}
                                    fullWidth
                                    disabled={!me.rootAdmin && !me.providerAdmin}
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <FormControl
                                    fullWidth
                                    disabled={(!me.rootAdmin && !me.providerAdmin) || ssoBranch === SSO_BRANCH[0]}
                                >
                                    <InputLabel>{i18n.tc('ssoAdmin.users.fields.shell')}</InputLabel>
                                    <Select
                                        value={loginShell}
                                        onChange={handleShellChange}
                                        label={i18n.tc('ssoAdmin.users.fields.shell')}
                                    >
                                        {['/bin/nologin', '/bin/bash', '/bin/zsh'].map((shell) => (
                                            <MenuItem key={shell} value={shell}>
                                                {shell}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.email')}
                                    disabled
                                    value={email}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.uuid')}
                                    disabled
                                    value={uuid}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.certSerial')}
                                    disabled
                                    value={certSerial || ''}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <FormControl fullWidth disabled>
                                    <InputLabel>{i18n.tc('ssoAdmin.users.fields.branch')}</InputLabel>
                                    <Select value={ssoBranch} label={i18n.tc('ssoAdmin.users.fields.branch')}>
                                        {SSO_BRANCH.map((branch) => (
                                            <MenuItem key={branch} value={branch}>
                                                {branch}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.gid')}
                                    disabled
                                    value={gidNumber || ''}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.directory')}
                                    disabled
                                    value={homeDirectory || ''}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <StyledSpacedWrapper>
                                <TextField
                                    label={i18n.tc('ssoAdmin.users.fields.uid')}
                                    disabled
                                    value={uidNumber || ''}
                                    fullWidth
                                />
                            </StyledSpacedWrapper>

                            <TextField
                                label={i18n.tc('ssoAdmin.users.fields.owner')}
                                disabled
                                value={owner || ''}
                                fullWidth
                            />
                        </ContentArea>
                    </DrawerContent>
                    <DrawerActions>
                        <Grid container justifyContent="flex-end" mt={1}>
                            <CancelButton onClick={onCancel} />
                            <EditButton onClick={handleEdit} disabled={!me.rootAdmin && !me.providerAdmin} />
                        </Grid>
                    </DrawerActions>
                </>
            )}

            {tab === 1 && (
                <DrawerContent>
                    <ContentArea>
                        <StyledButtonWrapper>
                            <ResetPasswordLink
                                email={email}
                                provider={providers && providers[0] !== '' ? providers[0] : null}
                            />
                        </StyledButtonWrapper>
                        <StyledButtonWrapper>
                            <ChangeEmailLinks
                                email={email}
                                provider={providers && providers[0] !== '' ? providers[0] : null}
                                onForceChangeSuccess={onUpdate}
                            />
                        </StyledButtonWrapper>
                        <StyledButtonWrapper>
                            {certSerial ? (
                                <RevokeCertificateLink email={email} onCertificateRevoked={onUpdate} ssoWs={ssoWs} />
                            ) : (
                                <GenerateCertificateLink email={email} onCertificateGenerated={onUpdate} />
                            )}
                        </StyledButtonWrapper>

                        {isBlocked ? (
                            <StyledButton variant="contained" fullWidth onClick={handleUnblockUser}>
                                unblock user
                            </StyledButton>
                        ) : (
                            <StyledButton variant="contained" fullWidth onClick={openBlockUserDialog}>
                                {i18n.tc('ssoAdmin.user.actions.blockUser.title')}
                            </StyledButton>
                        )}

                        {user.otp && (
                            <StyledButtonWrapper>
                                <StyledButton variant="contained" fullWidth onClick={handleRevoke2FA}>
                                    {i18n.tc('ssoAdmin.users.actions.revoke2FA')}
                                </StyledButton>
                            </StyledButtonWrapper>
                        )}
                    </ContentArea>
                </DrawerContent>
            )}

            {tab === 2 && <UserGrantsList uuid={uuid} email={email} ssoWs={ssoWs} />}
            {tab === 3 && <UserGroupsList memberEmail={email} ssoWs={ssoWs} />}
            {tab === 4 && <Prefs objectUuid={uuid} prefType={PREF_TYPES.USERS} onCancel={onCancel} />}

            <BlockUserDialog
                isOpen={blockUserDialogIsOpen}
                onCancel={closeBlockUserDialog}
                onEndDateSelection={onUserBlockingEndDateSelection}
            />
        </Edition>
    );
}

UserEdition.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
        ssoBranch: PropTypes.string.isRequired,
        providers: PropTypes.arrayOf(PropTypes.string),
        otp: PropTypes.bool.isRequired,
        providerAdmin: PropTypes.string,
        loginShell: PropTypes.string,
        homeDirectory: PropTypes.string,
        certSerial: PropTypes.string,
        gidNumber: PropTypes.string,
        uidNumber: PropTypes.string,
        owner: PropTypes.string,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isBlocked: PropTypes.bool.isRequired,
};

export default UserEdition;
