import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import './index.css';

import React, { useContext, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { SnackbarProvider } from 'notistack';
import merge from 'lodash.merge';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';

import { LabelsMapping } from '@braincube/ui-lab';
import { buildTheme } from '@braincube/theme';
import { I18nProvider, getLocaleKey } from '@braincube/i18n';
import {
    HeaderContextProvider,
    headerLabels,
    useUserPreferences,
    useSites,
    useMe,
    reactQueryHeaderPersister,
} from '@braincube/header';
import Logger from '@braincube/logger';
import { i18nLabels } from '@braincube/ui';
import { LabelsMapping as DataGridLabelsMapping } from '@braincube/data-grid';
import tracker from '@braincube/tracker';

import localesMap from './services/lang';
import { AppContext, AppContextProvider, setMeFromUniverse } from './app-context';
import App from './components/App';
import pkg from '../package.json';

LicenseInfo.setLicenseKey(import.meta.env.VITE_REACT_APP_XGRID_LICENCE_KEY);

function Test() {
    const {
        data: {
            general: { locale },
        },
    } = useUserPreferences();

    const { data: me } = useMe();

    const { dispatch } = useContext(AppContext);

    const { selectedSite } = useSites();

    useEffect(() => {
        if (selectedSite && me) {
            tracker.configure({
                appName: 'sso-admin',
                apiName: selectedSite.siteName,
                appVersion: import.meta.env.VITE_APP_VERSION,
                user: me.user,
                selectedSiteName: selectedSite.siteName,
                matomoSiteId: '22',
            });
        }
    }, [me, selectedSite]);

    useEffect(() => {
        if (me.groupsList) {
            setMeFromUniverse(dispatch, me.groupsList);
        }
    }, [me, dispatch]);

    const localeKey = getLocaleKey(locale);

    return (
        <I18nProvider
            labels={merge(headerLabels, localesMap, i18nLabels, LabelsMapping, DataGridLabelsMapping).getLabels(
                localeKey
            )}
            locale={localeKey}
        >
            <App />
        </I18nProvider>
    );
}

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

script.async = false;
script.type = 'text/javascript';

if (import.meta.env.DEV) {
    script.src = `https://cdn.test.mybraincube.com/configuration.js?timestamp=${Date.now()}`;
} else {
    script.src = `https://${window.location.hostname.replace('admin', 'cdn')}/configuration.js?timestamp=${Date.now()}`;
}

const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const theme = buildTheme();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
broadcastQueryClient({ queryClient, broadcastChannel: 'braincube' });

function Init() {
    useEffect(() => {
        Logger.configure({
            sentry: import.meta.env.VITE_LOGGER_SENTRY === 'true',
            appName: 'sso-admin',
            version: pkg.version,
            sentryProjectId: 21,
            sentryToken: '24583a93cbfd44549d0ffb3ce43c13a2',
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    maxSnack={1}
                    anchorOrigin={anchorOrigin}
                    autoHideDuration={import.meta.env.VITE_SNACKBAR_DELAY}
                >
                    <CssBaseline />
                    <AppContextProvider>
                        <Router>
                            <PersistQueryClientProvider
                                client={queryClient}
                                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                                persistOptions={{ persister: reactQueryHeaderPersister }}
                            >
                                <HeaderContextProvider>
                                    <Test />
                                </HeaderContextProvider>
                            </PersistQueryClientProvider>
                        </Router>
                    </AppContextProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

script.onload = function render() {
    // ReactDOM.render(<Init />, document.querySelector('#root'));
    createRoot(document.querySelector('#root')).render(<Init />);
};

head.appendChild(script);
