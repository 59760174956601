import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Tabs,
    IconButton,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    styled,
    Switch,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useI18n } from '@braincube/i18n';
import { useMe } from '@braincube/header';
import { DrawerContent, ContentArea, DrawerActions } from '@braincube/ui-lab';

import { StyledTab } from 'components/Routes/AppsManager/Apps/AppAddition';

import { AppContext, setIsFetching } from '../../../../app-context';
import SsoWsClient from '../../../../wsClient/SsoWsClient';
import EditButton from '../../../Buttons/Edit';
import ProductGrantsList from './ProductGrantsList';
import Edition from '../../../EntityManager/Edition';
import Accountant from './Accountant';
import Offers from './Offers';
import CancelButton from '../../../Buttons/Cancel';
import PRODUCT_TYPES from './productTypes';
import IoTStackField from './components/IoTStackField';
import Prefs, { PREF_TYPES } from '../../../Prefs';

const StyledCopyFieldContainer = styled(`div`)({
    display: 'flex',
    '& > div': {
        flex: 1,
    },
});

const StyledTerminalXSwitch = styled(Switch, {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})(({ theme, isDisabled }) => ({
    '& > span:first-of-type': {
        color: isDisabled ? theme.palette.error.main : theme.palette.success.main,
    },
    '& > span:last-of-type': {
        backgroundColor: isDisabled ? theme.palette.error.main : theme.palette.success.main,
    },
}));

function RemoteControl({ state, onChange }) {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = useCallback(
        (e, value) => {
            setIsDisabled(value);
            onChange(value);
        },
        [onChange]
    );

    useEffect(() => {
        setIsDisabled(state);
    }, [state]);

    return (
        <StyledTerminalXSwitch
            color="error"
            data-testid="terminalXController"
            isDisabled={isDisabled}
            defaultChecked={isDisabled}
            checked={isDisabled}
            onChange={handleChange}
        />
    );
}

const ssoWs = new SsoWsClient();

/**
 * Edit product management
 */
function ProductEdition({ providersProductsList, product, licence, onUpdate, onCancel }) {
    const { data: me } = useMe();

    const { dispatch } = useContext(AppContext);

    const data = useMemo(() => {
        try {
            return JSON.parse(product.data);
        } catch (e) {
            return {
                internal_deployment: false,
                renew: '',
                terminal_x_disabled: false,
                number_available_childs: 1,
            };
        }
    }, [product]);

    const i18n = useI18n();
    const [name, setName] = useState(product.name || '');
    const [clientName, setClientName] = useState(product.clientName || '');
    const [siteName, setSiteName] = useState(product.siteName || '');
    const [url, setUrl] = useState(product.url || '');
    const [cloudIot, setCloudIot] = useState(data.internal_deployment || false);
    const [licenceValidity, setLicenceValidity] = useState(data.renew || '');
    const [terminalXDisabled, setTerminalXDisabled] = useState(data.terminal_x_disabled || false);
    const [maxChildren, setMaxChildren] = useState(data.number_available_childs || 1);
    const [tab, setTab] = useState(0);
    const [idSap, setIdSap] = useState('');
    const [stackId, setStackId] = useState(null);
    const inputEl = useRef(null);
    const inputEld = useRef(null);
    const inputLicence = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setName(product.name || '');
        setClientName(product.clientName || '');
        setSiteName(product.siteName || '');
        setUrl(product.url || '');
        setCloudIot(data.internal_deployment || false);
        setLicenceValidity(data.renew || '');
        setTerminalXDisabled(data.terminal_x_disabled || false);
        setMaxChildren(data.number_available_childs || 1);

        if (product.stackId) {
            const iotStackProduct = providersProductsList.find(
                (productEntry) => productEntry.productId === product.stackId
            );

            if (iotStackProduct) {
                setStackId({
                    label: iotStackProduct.name,
                    value: iotStackProduct.productId,
                });
            } else {
                setStackId(null);
            }
        } else {
            setStackId(null);
        }

        ssoWs.getIdSAP(product.providerName, product.clientName, product.siteName, {
            onSuccess: ({ response }) => {
                setIdSap(response);
            },
            onError: () => {
                setIdSap(null);
            },
        });
    }, [
        data.internal_deployment,
        data.number_available_childs,
        data.renew,
        data.terminal_x_disabled,
        product,
        providersProductsList,
    ]);

    const enableTerminalXManagement = useMemo(
        () => [PRODUCT_TYPES.IOT.value, PRODUCT_TYPES.IOT_STACK.value].includes(product.type),
        [product.type]
    );

    const enableMaxChildrenSet = useMemo(
        () => licence && product.type === PRODUCT_TYPES.IOT_STACK.value,
        [licence, product.type]
    );

    const handleEdit = useCallback(async () => {
        dispatch(setIsFetching(true));

        if (product.type === PRODUCT_TYPES.IOT.value) {
            await ssoWs.updateProductDataKey(
                product.providerName,
                product.clientName,
                product.siteName,
                product.productId,
                'renew',
                licenceValidity.toString(),
                {
                    onSuccess: () => {},
                    onError: () => {},
                }
            );
        }

        if ([PRODUCT_TYPES.IOT.value, PRODUCT_TYPES.IOT_STACK.value].includes(product.type)) {
            await ssoWs.updateProductDataKey(
                product.providerName,
                product.clientName,
                product.siteName,
                product.productId,
                'internal_deployment',
                cloudIot.toString(),
                {
                    onSuccess: () => {},
                    onError: () => {},
                }
            );
        }

        if (enableMaxChildrenSet) {
            await ssoWs.updateProductDataKey(
                product.providerName,
                product.clientName,
                product.siteName,
                product.productId,
                'number_available_childs',
                maxChildren.toString(),
                {
                    onSuccess: () => {},
                    onError: () => {},
                }
            );
        }

        if (enableTerminalXManagement) {
            await ssoWs.updateProductDataKey(
                product.providerName,
                product.clientName,
                product.siteName,
                product.productId,
                'terminal_x_disabled',
                terminalXDisabled.toString(),
                {
                    onSuccess: () => {},
                    onError: () => {},
                }
            );
        }

        await ssoWs.updateProduct(
            product.providerName,
            {
                ...product,
                name,
                clientName,
                siteName,
                url,
                stackId: stackId?.value || null,
            },
            product.productId,
            {
                onSuccess: () => {
                    dispatch(setIsFetching(false));
                    enqueueSnackbar(i18n.tc('ssoAdmin.products.notistack.update.success'), { variant: 'success' });
                    onUpdate();
                },
                onError: (brainWsResponse, xhr) => {
                    dispatch(setIsFetching(false));
                    enqueueSnackbar(xhr.response, { variant: 'error' });
                    onCancel();
                },
            }
        );
    }, [
        dispatch,
        product,
        enableTerminalXManagement,
        enableMaxChildrenSet,
        name,
        clientName,
        siteName,
        url,
        stackId?.value,
        licenceValidity,
        cloudIot,
        maxChildren,
        terminalXDisabled,
        enqueueSnackbar,
        i18n,
        onUpdate,
        onCancel,
    ]);

    const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

    const handleNameChange = useCallback((e) => setName(e.target.value), []);

    const handleGroupNameChange = useCallback((e) => setClientName(e.target.value), []);

    const handleSiteNameChange = useCallback((e) => setSiteName(e.target.value), []);

    const handleUrlChange = useCallback((e) => setUrl(e.target.value), []);

    const handleCopy = useCallback(() => {
        inputEl.current.disabled = false;
        inputEl.current.select();
        inputEl.current.disabled = true;
        document.execCommand('copy');
    }, []);

    const handleIotChange = useCallback((e) => setCloudIot(e.target.checked), []);

    const iotCheckbox = useMemo(
        () => <Checkbox checked={cloudIot === 'true' || cloudIot} onChange={handleIotChange} />,
        [cloudIot, handleIotChange]
    );

    const handleLicenceValidityChange = useCallback((e) => setLicenceValidity(e.target.value), []);

    const handleRemoteControlChange = useCallback((value) => {
        setTerminalXDisabled(value);
    }, []);

    const handleMaxChildrenChange = useCallback((e) => {
        setMaxChildren(e.target.value);
    }, []);

    const displayRemoteControl = useMemo(
        () => <RemoteControl state={terminalXDisabled} onChange={handleRemoteControlChange} />,
        [handleRemoteControlChange, terminalXDisabled]
    );

    const handleStackIdChange = useCallback((value) => {
        setStackId(value);
    }, []);

    return (
        <Edition label={i18n.tc('ssoAdmin.products.edition.title')} onCancel={onCancel}>
            <Tabs
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                value={tab}
                onChange={handleTabChange}
            >
                <StyledTab label={i18n.tc('ssoAdmin.tabs.general')} />
                <StyledTab label={i18n.tc('ssoAdmin.tabs.accountant')} />
                <StyledTab label={i18n.tc('ssoAdmin.tabs.grants')} />
                <StyledTab label={i18n.tc('ssoAdmin.tabs.offers')} />
                <StyledTab label="Prefs" />
            </Tabs>
            {tab === 0 && (
                <>
                    <DrawerContent>
                        <ContentArea>
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <TextField
                                        label={i18n.tc('ssoAdmin.products.fields.name')}
                                        value={name}
                                        onChange={handleNameChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        disabled
                                        label={i18n.tc('ssoAdmin.products.fields.groupName')}
                                        value={clientName}
                                        onChange={handleGroupNameChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        disabled
                                        label={i18n.tc('ssoAdmin.products.fields.siteName')}
                                        value={siteName}
                                        onChange={handleSiteNameChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.tc('ssoAdmin.products.fields.url')}
                                        value={url}
                                        onChange={handleUrlChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <StyledCopyFieldContainer>
                                        <TextField
                                            label={i18n.tc('ssoAdmin.products.fields.productId')}
                                            value={product.productId}
                                            disabled
                                            inputRef={inputEl}
                                        />
                                        <IconButton onClick={handleCopy} size="large">
                                            <FileCopyIcon />
                                        </IconButton>
                                    </StyledCopyFieldContainer>
                                </Grid>

                                {idSap ? (
                                    <Grid item>
                                        <StyledCopyFieldContainer>
                                            <TextField
                                                label={i18n.tc('ssoAdmin.products.fields.idSap')}
                                                value={idSap}
                                                disabled
                                                inputRef={inputEld}
                                            />
                                            <IconButton onClick={handleCopy} size="large">
                                                <FileCopyIcon />
                                            </IconButton>
                                        </StyledCopyFieldContainer>
                                    </Grid>
                                ) : (
                                    ''
                                )}

                                {/\S/.test(licence) && (
                                    <Grid item>
                                        <StyledCopyFieldContainer>
                                            <TextField
                                                label={i18n.tc('ssoAdmin.products.fields.licence')}
                                                value={licence}
                                                disabled
                                                inputRef={inputLicence}
                                            />
                                            <IconButton onClick={handleCopy} size="large">
                                                <FileCopyIcon />
                                            </IconButton>
                                        </StyledCopyFieldContainer>
                                    </Grid>
                                )}
                                <Grid item>
                                    <TextField
                                        label={i18n.tc('ssoAdmin.products.fields.type')}
                                        disabled
                                        value={product.type}
                                        fullWidth
                                    />
                                </Grid>

                                {[PRODUCT_TYPES.IOT.value, PRODUCT_TYPES.IOT_STACK.value].includes(product.type) && (
                                    <Grid item>
                                        <FormControl fullWidth disabled={!me.user.rootAdmin}>
                                            <FormControlLabel
                                                control={iotCheckbox}
                                                label={i18n.tc('ssoAdmin.products.fields.cloudIot')}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}

                                {product.type === PRODUCT_TYPES.IOT.value && (
                                    <>
                                        <Grid item>
                                            <TextField
                                                label={i18n.tc('ssoAdmin.products.fields.licenceValidity')}
                                                disabled={!me.user.rootAdmin}
                                                type="number"
                                                value={licenceValidity}
                                                onChange={handleLicenceValidityChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IoTStackField
                                                siteUuid={product.site.siteUuid}
                                                providersProducts={providersProductsList}
                                                value={stackId}
                                                onChange={handleStackIdChange}
                                            />
                                        </Grid>
                                    </>
                                )}

                                {enableMaxChildrenSet && (
                                    <Grid item>
                                        <TextField
                                            data-testid="maxChildrenField"
                                            label={i18n.tc('ssoAdmin.products.fields.maxChildren')}
                                            disabled={!me.user.rootAdmin}
                                            type="number"
                                            value={maxChildren}
                                            onChange={handleMaxChildrenChange}
                                            fullWidth
                                        />
                                    </Grid>
                                )}

                                {enableTerminalXManagement && (
                                    <Grid item>
                                        <FormControlLabel
                                            control={displayRemoteControl}
                                            label={i18n.tc('ssoAdmin.products.fields.terminalXDisabled')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </ContentArea>
                    </DrawerContent>
                    <DrawerActions>
                        <Grid container justifyContent="flex-end" mt={1}>
                            <CancelButton onClick={onCancel} />
                            <EditButton onClick={handleEdit} disabled={!name} />
                        </Grid>
                    </DrawerActions>
                </>
            )}
            {tab === 1 && <Accountant onClose={onCancel} product={product} provider={product.providerName} />}
            {tab === 2 && (
                <ProductGrantsList
                    productId={product.productId}
                    providerName={product.providerName}
                    ssoWs={ssoWs}
                    productType={product.type}
                />
            )}
            {tab === 3 && <Offers product={product} />}
            {tab === 4 && <Prefs objectUuid={product.productId} prefType={PREF_TYPES.PRODUCTS} onCancel={onCancel} />}
        </Edition>
    );
}

ProductEdition.propTypes = {
    providersProductsList: PropTypes.arrayOf(
        PropTypes.shape({
            productId: PropTypes.string.isRequired,
            site: PropTypes.shape({
                siteUuid: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
    product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        clientName: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        providerName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        data: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        productId: PropTypes.string.isRequired,
    }).isRequired,
    licence: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ProductEdition;
